import React from 'react'
import Layout from '../components/layout'

import { Section } from '../components/sections'
import { Grid } from '../components/grid'

const NotFoundPage = () => (
  <Layout>
    <Section>
      <Grid>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Grid>
    </Section>
  </Layout>
)

export default NotFoundPage
